import * as React from "react";
import Layout from "../../components/Layout";
import GeneralTab from "../../components/HTabs";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
// import Hero from "../../components/Hero";
import About from "../../components/About";
import Document from "../../components/Document";
import ProcedureSteps from "../../components/ProcedureSteps";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function ProducerCompany() {
  // Banner slider
  // const ProducerSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Producer Company Registration Service`,
  //     content: `Best Producer Company Online Registration Platform `,
  //     image: "/imgs/business/sliders/producer-comp.png",
  //     alt_tag: "Best Online Producer Company Registration Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 40,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Producer Company Registration Service`,
  //     content: `Apply for Producer Company Online Registration with TODAYFILINGS Experts`,
  //     image: "/imgs/business/sliders/producer-slider.png",
  //     alt_tag: "Best Online Producer Company Registration Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Producer Company Registration`,
  //     content: `Fast and Secure Producer Company Registration Service in Hosur`,
  //     image: "/imgs/business/sliders/producer-sliders.png",
  //     alt_tag: "Best Online Producer Company Registration in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Producer Company Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `34999`,
    f_price: `39999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data Without Form*/
  const PcAboutData = {
    title: `Online Producer Company Registration `,
    sub_title: `Producer Company Registration in just 7 Days`,
    paragraph: `"Producer Company" is a business that was formally
  established with the goal of raising the standards of
  farmers and agriculturalists. The Producer Company
  Registration in India can be founded with a minimum of 10
  individuals or more, with 2 institutions or more, or with a
  mix of both, such as 10 individuals and 2 institutions, in
  accordance with the Companies Act 1956, which governs the
  formation of this type of company. Additionally, it should
  contain one of the following objectives for business.`,
    header: `Our Features in Producer Company Registration`,
    points: [
      `Only equity shares make up a production company's share capital`,
      `Producer company's name must include the phrase "Producer Company Limited."`,
    ],
  };

  /* Tabs Data */
  const PcTabsData = {
    id: 'features',
    heading: 'Producer Company Registration Online',
    body: [
      {
        tab_title: 'Advantages',
        tab_content: [
          {
            content_title: 'Limited Liability',
            content_paragraph: `Members of the Producer Company have limited liability,
              and they are not permitted to use their own assets to pay
              off the company's losses and debt.`,
            points: [],
          },
          {
            content_title: 'Separate Legal Entity',
            content_paragraph: `Registered producer business is considered as a distinct
              legal entity with the ability to buy or sell land in its
              own name, just like a private limited company is.`,
            points: [],
          },
        ],
        content_img: '/imgs/business/credibility.png',
        alt_tag: 'TODAYFILINGS Foreign Income',
      },
      {
        tab_title: 'Register Advantages',
        tab_content: [
          {
            content_title: 'Better Reputation',
            content_paragraph: `Companies that are registered producers are given more
            trust than companies that are not registered producers.`,
            points: [],
          },
          {
            content_title: 'Ability To Manage',
            content_paragraph: `By completing a few straightforward forms and submitting
            them to the registrar of companies, a production company applicant can request
            desired changes to the board of management (ROC)`,
            points: [],
          },
        ],
        content_img: '/imgs/business/requirements.png',
        alt_tag:
          'TODAYFILINGS is the Best Producer Company Registration service Providers',
      },
      {
        tab_title: 'Checklist',
        tab_content: [
          {
            content_title:
              'Checklist to form a Producer Company Registration in India',
            content_paragraph: '',
            points: [
              `The firm must have at least 10 members and 5 directors to be incorporated.`,
              `To register a business, you need at least Rs 5 lakhs in paid-up capital.`,
              `No maximum number of members is necessary to form a producer business.`,
              `This kind of business cannot be categorized as a public limited company or registered as one.`,
              `Only equity share capital may be owned by the producer firm.`,
              `The business should hold four board meetings each year, one every three months.`,
            ],
          },
        ],
        content_img: '/imgs/business/checklist-pro.png',
        alt_tag:
          'TODAYFILINGS is the Best Online Producer Company Registration Service Providers Hosur',
      },
      {
        tab_title: 'Requirements',
        tab_content: [
          {
            content_title: `Additional Mandatory Conditions for Registration of a
            Producer Company in India`,
            content_paragraph: '',
            points: [
              `Only one person or member may participate in ownership of the
              company and engage in farming operations associated with
              the production of primary products.`,
              `The company's employees should all be primary producers.`,
              `Members of the producer company formation are only
              liable for the amount of their unpaid shares.`,
              `Producer Company Limited should be added after the
              company's name in the proposed business.`,
              `The proposed Producer Company is regarded as a private
              limited company under the provisions of the Companies
              Act.`,
            ],
          },
        ],
        content_img: '/imgs/business/bank.png',
        alt_tag:
          'TODAYFILINGS Best Online Producer Company Registration Service Providers Near Bangalore',
      },
    ],
  };
  /* FaQ */
  var ProducerCompFAQ = [
    {
      header: 'What kind of company is Producer Company?',
      body: [
        {
          content: `The producing Company is envisioned as a Public Limited Company in
          accordance with Section 581C of the Companies Act, 1956.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'Who can become a member or director of a Producer Company?',
      body: [
        {
          content: `A "Producer" or "Producer institution" can become a member or
          director of the producer company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can Producer Company provide special rights to its members?',
      body: [
        {
          content: `The producing firm may grant unique rights to the Company's members
          if the Articles of Association stipulate any such rights.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How Much Time Does It Take To Form a Producer Company?',
      body: [
        {
          content: `Forming a producer company from start can take around 35-40 working days on average.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'Can shares of the company be transferred?',
      body: [
        {
          content: `A member of the Producer Company may transfer shares with any
          special rights after receiving the required board of directors
          approval.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: 'Can a nominee of the member be appointed in a Producer Company?',
      body: [
        {
          content: `The member of the Producer Company must designate, within three
          months of joining, a person to whom the shares will be transferred
          in the event of the member's passing.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is a Producer Company a Private or a Public Company?',
      body: [
        {
          content: `From the name of the producer company, it may seem that the producer companies are public companies.
          Still, on the contrary, the Companies Act, 1956, section 581C, suggests that a producer company cannot be deemed
          a public company and shall become a corporate similar to a private company.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What Is the Minimum Capital Required to Form a Producer Company?',
      body: [
        {
          content: `The company requires a minimum of ₹5,00,000 as capital to incorporate a producer company.`,
          icon: false,
        },
      ],
    },
    {
      header: 'How Many Directors Can a Farmer Producer Company Have?',
      body: [
        {
          content: `A producer company should have at least five directors on its board with an upper limit of up to
           15 directors.The company can also have additional or expert directors whose number, however, shall not exceed
           one-fifth of the total strength of the number of directors on the Board.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'What Is the Period of Tenure of the Directors of the Producer Company?',
      body: [
        {
          content: `A director shall be appointed for a minimum of one year but not more than five years. Also, every
          retired director is eligible to be re-elected by the members as a director.`,
          icon: false,
        },
      ],
    },
  ];

  /* Document Section Data */
  const PcDocData = {
    id: 'pc-doc',
    heading: `Rules and Guidelines for Quick Access to Loans and Credits`,
    sub_heading: '',
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/loans.png`,
        alt_tag: `Loans`,
        header: `Loans`,
        description: `In accordance with the legislation, loans and advances must
        be made to company members in exchange for security.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/credit.png`,
        alt_tag: `Credit Facilities`,
        header: `Credit Facilities`,
        description: `Credit facilities must be
        provided to producers in accordance with the law,  or other firm members for a term of
        six months or less.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/nabard.png`,
        alt_tag: `NABARD Loan`,
        header: `NABARD Loan`,
        description: `Nidhi Company is also required to submit a half-yearly The
        National Bank for Agriculture & Rural Development, or NABARD.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/advance.png`,
        alt_tag: `Advances`,
        header: `Advances`,
        description: `According to the Loans and advances to
         business members must be made in line with the law in exchange for security.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/law.png`,
        alt_tag: `Loan Facilities`,
        header: `Loan Facilities`,
        description: `Loan facilities would be provided according to the law, to
        the company's producers or members for a duration of six months or less.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/bank.png`,
        alt_tag: `Bank Loan`,
        header: `Bank Loan`,
        description: `Small and regional farmers are assisted in meeting their
        financial needs through NABARD, the National Bank for
        Agriculture & Rural Development.`,
      },
    ],
  };
  /* Procedure Steps Data */
  const PcStepsData = {
    heading: 'Producer Company Online Registration Procedure',
    paragraph: `According to the rules and regulations of the ministry of
    corporate affairs, the registration process of Producer
    Company in India is the same as a Private limited company.`,
    body: [
      {
        header: 'Step 1',
        content: `Obtaining Digital Signature Certificate (DSC) and
        Director Identification Number (DIN) for the company's
        directors.`,
      },
      {
        header: 'Step 2',
        content: `Selecting the distinctive name and Getting the obtaining
        name approval for the given certificate.`,
      },
      {
        header: 'Step 3',
        content: `Drafting and submitting the MoA & AoA along with the
        subscription statement to the Registrar of Companies (ROC).`,
      },
      {
        header: 'Step 4',
        content: `Obtaining the certificate of incorporation (CIN), which
        takes between 15 to 25 days, is the fourth step.`,
      },
      {
        header: 'Step 5',
        content: `The company must apply for a PAN and TAN, which
        typically takes 7 working days to get.`,
      },
      {
        header: 'Step 6',
        content: `The MoA, AoA, Certificate of Incorporation, and PAN of
        the firm must be shown to the bank in order to open a
        bank account.`,
      },
    ],
  };

  return (
    <div>
      <Seo
        title='Producer Company'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ProducerSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={PcAboutData} />

          <Counter />

          <GeneralTab HTabsData={PcTabsData} />

          <Document docSecData={PcDocData} />

          <ProcedureSteps stepsData={PcStepsData} />
          <Cta />
          <FAQAccord items={ProducerCompFAQ} />
        </main>
      </Layout>
    </div>
  );
}
